import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type Gender } from '@/constants/gender-options'
import { type LabelAndValue } from '@/types/label-and-value'
import { type UserProfile } from '@/types/user-profile'
import { type UserStatus } from '@/types/user-status'
import { getUserProfiles } from '@/utils/get-user-profiles'
import {
  date,
  requiredString,
  userStatus,
  gender,
  userProfiles,
  labelAndValue,
  arrayOfOptions
} from '@/utils/zod'

const customSchedule = z.array(
  z.object({
    time_from: z.string(),
    time_to: z.string()
  })
)

const responseSchema = z.object({
  id: requiredString(),
  name: requiredString(),
  first_name: requiredString(),
  last_name: requiredString(),
  status: userStatus.optional(),
  email: z.string(),
  phone_number: z.string().nullable().optional(),
  emergency_phone_number: z.string().nullable().optional(),
  is_teacher: z.boolean(),
  is_superuser: z.boolean(),
  is_support_staff: z.boolean(),
  profiles: userProfiles,
  gender: gender().nullable(),
  date_of_birth: date().nullable(),
  nationalities: arrayOfOptions,
  first_language: labelAndValue.nullable(),
  ahv_number: z.string(),
  community: labelAndValue.nullable(),
  can_remove_teacher: z.boolean(),
  can_remove_tutor: z.boolean(),
  tutor: z
    .object({
      classes: z.array(
        z.object({
          id: requiredString(),
          name: requiredString()
        })
      )
    })
    .nullable()
    .optional(),
  teacher: z
    .object({
      custom_schedule: z
        .object({
          '0': customSchedule,
          '1': customSchedule,
          '2': customSchedule,
          '3': customSchedule,
          '4': customSchedule,
          '5': customSchedule,
          '6': customSchedule
        })
        .nullable(),
      preferred_rooms: z
        .array(
          z.object({
            id: requiredString(),
            name: requiredString()
          })
        )
        .nullable()
        .optional()
    })
    .nullable()
    .optional()
})

export type Schedule = {
  timeFrom: string
  timeTo: string
}

export type User = {
  id: string
  name: string
  firstName: string
  lastName: string
  status?: UserStatus
  email: string
  phoneNumber?: string
  emergencyPhoneNumber?: string
  isTeacher: boolean
  isSuperuser: boolean
  isSupportStaff: boolean
  profiles: UserProfile[]
  gender?: Gender
  dateOfBirth?: Date
  nationalities: LabelAndValue[]
  firstLanguage?: LabelAndValue
  community?: LabelAndValue
  ahvNumber: string
  canRemoveTeacher: boolean
  canRemoveTutor: boolean
  tutor?: {
    classes: {
      id: string
      name: string
    }[]
  } | null
  teacher?: {
    customSchedule?: {
      monday: Schedule[]
      tuesday: Schedule[]
      wednesday: Schedule[]
      thurdsay: Schedule[]
      friday: Schedule[]
      saturday: Schedule[]
      sunday: Schedule[]
    }
    preferredRooms?: {
      id: string
      name: string
    }[]
  }
}

const parseSchedule = (teacherSchedule: z.infer<typeof customSchedule>) =>
  teacherSchedule.map(schedule => ({
    timeFrom: schedule.time_from,
    timeTo: schedule.time_to
  }))

const parseResponse = (response: z.infer<typeof responseSchema>): User => ({
  id: response.id,
  name: response.name,
  firstName: response.first_name,
  lastName: response.last_name,
  status: response.status,
  email: response.email,
  phoneNumber: response.phone_number || undefined,
  emergencyPhoneNumber: response.emergency_phone_number || undefined,
  gender: response.gender || undefined,
  dateOfBirth: response.date_of_birth
    ? new Date(response.date_of_birth)
    : undefined,
  nationalities: response.nationalities,
  firstLanguage: response.first_language || undefined,
  ahvNumber: response.ahv_number,
  community: response.community || undefined,
  isTeacher: response.is_teacher,
  isSuperuser: response.is_superuser,
  isSupportStaff: response.is_support_staff,
  profiles: getUserProfiles({
    isSuperUser: response.is_superuser,
    isSupportStaff: response.is_support_staff,
    profiles: response.profiles
  }),
  tutor: response.tutor || undefined,
  canRemoveTeacher: response.can_remove_teacher,
  canRemoveTutor: response.can_remove_tutor,
  teacher: response.teacher
    ? {
        customSchedule: response.teacher.custom_schedule
          ? {
              monday: parseSchedule(response.teacher.custom_schedule['0']),
              tuesday: parseSchedule(response.teacher.custom_schedule['1']),
              wednesday: parseSchedule(response.teacher.custom_schedule['2']),
              thurdsay: parseSchedule(response.teacher.custom_schedule['3']),
              friday: parseSchedule(response.teacher.custom_schedule['4']),
              saturday: parseSchedule(response.teacher.custom_schedule['5']),
              sunday: parseSchedule(response.teacher.custom_schedule['6'])
            }
          : undefined,
        preferredRooms: response.teacher.preferred_rooms
          ? response.teacher.preferred_rooms
          : undefined
      }
    : undefined
})

export const userQueryOptions = (id: string) => ({
  queryKey: ['panelUserRetrieve', id],
  staleTime: 60 * 100,
  queryFn: async () => {
    const userResponse = await httpService.panel.panelUsersRetrieve(id, {
      fetchKeys: {
        id: true,
        name: true,
        first_name: true,
        last_name: true,
        status: true,
        email: true,
        phone_number: true,
        emergency_phone_number: true,
        is_teacher: true,
        is_superuser: true,
        profiles: true,
        gender: true,
        date_of_birth: true,
        nationalities: true,
        first_language: true,
        ahv_number: true,
        community: true,
        tutor: true,
        teacher: true,
        can_remove_teacher: true,
        can_remove_tutor: true,
        is_support_staff: true
      }
    })

    return parseResponse(responseSchema.parse(userResponse))
  }
})

const useUser = (id: string) => useQuery(userQueryOptions(id))

export default useUser
