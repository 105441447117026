import { getRouteApi } from '@tanstack/react-router'
import clsx from 'clsx'
import { Trans, useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import { toast } from '@/hooks/useToast'

import styles from './ResetPasswordView.module.scss'
import Layout from '../components/Layout'
import Resend from '../components/Resend'
import ResetPasswordForm from '../components/ResetPasswordForm'
import useResetPassword from '../mutations/useResetPassword'

const routeApi = getRouteApi('/reset-password')

const ResetPasswordView = () => {
  const { t } = useTranslation('auth')
  const {
    mutate: resetPassword,
    formErrors,
    isPending,
    isSuccess,
    variables
  } = useResetPassword()

  const search = routeApi.useSearch()

  const { mutate: resendResetLink, isPending: isResetLinkPending } =
    useResetPassword({
      onSuccess: () =>
        toast({
          title: t('notification.link-to-reset-password-send'),
          variant: 'success'
        })
    })

  return isSuccess && variables ? (
    <Layout
      header={t('header.check-your-email')}
      subheader={
        <Trans
          t={t}
          i18nKey="text.email-to-reset-password-send"
          values={{
            EMAIL: variables.email
          }}
          components={{ strong: <span className={styles.bold} /> }}
        />
      }
    >
      <Button
        asLink
        to="/login"
        search={{ redirect: search.redirect }}
        className={clsx(styles.backToLogin)}
        variant="secondary"
      >
        {t('button.back-to-login')}
      </Button>

      <Resend
        copyText={t('text.not-receive-email')}
        onResend={() => resendResetLink({ email: variables.email })}
        loading={isResetLinkPending}
      />
    </Layout>
  ) : (
    <Layout
      header={t('header.reset-password')}
      subheader={t('text.enter-email-to-reset-password')}
    >
      <ResetPasswordForm
        errors={formErrors}
        onSubmit={resetPassword}
        loading={isPending}
      />
      <Button
        asLink
        to="/login"
        search={{ redirect: search.redirect }}
        className={clsx(styles.backToLogin, styles.resetPassword)}
        variant="tertiary"
      >
        {t('button.back-to-login')}
      </Button>
    </Layout>
  )
}
export default ResetPasswordView
