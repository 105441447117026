import type {
  CommunityEnum,
  FirstLanguageEnum,
  NationalitiesEnum
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import { USER_PROFILE } from '@/constants/user-profile'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'

import {
  userFormfieldErrorsMapper,
  type EditUserPayload
} from '../constants/user-payload'

const useEditUser = (options?: {
  onSuccess?: (validData: EditUserPayload) => void
  onError?: (validData: EditUserPayload) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: EditUserPayload) =>
      httpService.panel.panelUsersPartialUpdate(data.id, {
        email: data.email,
        phone_number: data.phoneNumber,
        emergency_phone_number: data.emergencyPhoneNumber,
        first_name: data.firstName,
        last_name: data.lastName,
        ahv_number: data.ahvNumber,
        date_of_birth: data.birthDate ? formatApiDate(data.birthDate) : null,
        gender: data.gender,
        nationalities: data.nationalities as NationalitiesEnum[],
        first_language: data.firstLanguage as FirstLanguageEnum,
        community: data.community as CommunityEnum,
        tutor: data.tutor,
        teacher: data.teacher,
        is_superuser: data.profile.includes(USER_PROFILE.SUPERUSER),
        is_support_staff: data.profile.includes(USER_PROFILE.SUPPORT_STAFF),
        send_activation_link: !!data.sendActivationLink
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    onError: (_, variables) => options?.onError?.(variables),
    fieldErrorsMapper: userFormfieldErrorsMapper
  })

export default useEditUser
