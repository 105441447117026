import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type UserProfile } from '@/types/user-profile'
import { type UserStatus } from '@/types/user-status'

const responseSchema = z.array(z.object({ id: z.string() }))

type Params = {
  id?: string[]
  profile?: UserProfile[]
  email?: string[]
  phoneNumber?: string[]
  status?: UserStatus[]
}

const useUsersIds = (params: Params = {}) =>
  useQuery({
    queryKey: ['userListIds', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const userList = await httpService.panel.panelUsersList({
        page: 1,
        page_size: 'max',
        id: params.id,
        email: params.email,
        phone_number: params.phoneNumber,
        profile: params.profile,
        status: params.status,
        fetchKeys: {
          id: true
        }
      })

      return {
        list: responseSchema.parse(userList.results).map(user => user.id),
        count: userList.count
      }
    }
  })

export default useUsersIds
