import i18n from '@/i18n'

export const commentTypeTags = {
  negative: { color: 'red', label: i18n.t('label.negative', { ns: 'common' }) },
  positive: {
    color: 'green',
    label: i18n.t('label.positive', { ns: 'common' })
  },
  neutral: {
    color: 'gray',
    label: i18n.t('label.neutral', { ns: 'common' })
  }
} as const
