import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './ParentDetailsView.module.scss'
import useParent from '../queries/useParent'
import { getParentStatusTootlipText } from '../utils/statuses'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/parents/$parentId/edit'
)

const ParentDetailsView = () => {
  const { t } = useTranslation('students')
  const { parentId } = routeApi.useParams()

  const { data: parent } = useParent(parentId)

  return (
    <BasicLayout
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{parent?.fullName}</h1>
          {parent?.status ? (
            <Tooltip
              trigger={
                <Tag
                  {...userStatusTags[parent.status]}
                  dataTestId="status-tag"
                  variant="on-blue"
                />
              }
              text={getParentStatusTootlipText(parent.status)}
            />
          ) : null}
        </div>
      }
      moduleName={t('header.academics', { ns: 'common' })}
    >
      Edit form - TODO
    </BasicLayout>
  )
}

export default ParentDetailsView
