import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

import type { AddGradesInAssignmentPayload } from '../constants/assignment-payload'

const useAddGradesInAssignments = (options?: { onSuccess: () => void }) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: AddGradesInAssignmentPayload) => {
      const payload = {
        items: data.items.map(item => ({
          id: item.id,
          mark_id: item.markId || null
        }))
      }

      return httpService.panel.panelGradesGradeItemsBulkUpdate(payload)
    },
    onSuccess: () => options?.onSuccess?.(),
    fieldErrorsMapper: field => {
      const [fieldName, index, key] = field.split('.')
      if (fieldName === 'items' && key === 'mark_id')
        return `items.${Number(index)}.markId`
      return 'root'
    }
  })

export default useAddGradesInAssignments
