import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'
import { getFullName } from '@/utils/get-full-name'

import type { GuardianForm } from '../constants/student-payload'

type GuardianRemoveModalProps = {
  guardian: GuardianForm
  onRemove: () => void
  open: boolean
  onOpenChange: (isOpen: boolean) => void
}

export const GuardianRemoveModal = (props: GuardianRemoveModalProps) => {
  const { t } = useTranslation('students')
  return (
    <ConfirmModal
      id="remove-guardian"
      open={props.open}
      header={t('header.remove-guardian')}
      subheader={t('text.are-you-sure-you-want-to-remove-guardian', {
        GUARDIAN_NAME: getFullName(props.guardian)
      })}
      onCancel={() => props.onOpenChange(false)}
      onOpenChange={props.onOpenChange}
      confirmButton={
        <Button variant="danger" onClick={props.onRemove}>
          {t('button.remove')}
        </Button>
      }
    />
  )
}
