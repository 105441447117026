import { Link, type LinkProps } from '@tanstack/react-router'
import clsx from 'clsx'
import React from 'react'

import type { routeTree } from '@/routeTree.gen'

import styles from './ButtonIcon.module.scss'

export type ButtonIconSize = 'extra-small' | 'small' | 'medium' | 'large'

type ButtonIconCommonProps = {
  size: ButtonIconSize
  variant: 'primary' | 'secondary' | 'tertiary' | 'success' | 'danger'
  className?: string
}

type ButtonIconLinkProps = LinkProps<typeof routeTree>

type ButtonIconButtonProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  onPointerDown?: () => void
  children: React.ReactNode
  dataTestId?: string
  disabled?: boolean
  ariaLabel: string
  ariaChecked?: boolean
  ariaControls?: string
  role?: 'button' | 'switch'
  tabIndex?: number
}

type ButtonIconProps = ButtonIconCommonProps &
  (
    | ({
        asLink?: undefined
      } & ButtonIconButtonProps)
    | ({
        asLink: true
      } & ButtonIconLinkProps)
  )

const ButtonIcon = (
  { size, variant, className, ...props }: ButtonIconProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const buttonClassName = clsx(
    styles.buttonIcon,
    styles[variant],
    styles[size],
    className
  )

  if (props.asLink) {
    const { asLink: _, ...linkProps } = props
    return <Link {...linkProps} className={buttonClassName} />
  }

  const {
    ariaChecked,
    ariaControls,
    ariaLabel,
    dataTestId,
    // rest should be valid button attributes
    // they need to be spread to work with radix tooltip with asChild
    ...buttonProps
  } = props

  return (
    <button
      {...buttonProps}
      ref={ref}
      className={buttonClassName}
      type="button"
      aria-controls={ariaControls}
      data-test-id={dataTestId}
      aria-checked={ariaChecked}
      aria-label={ariaLabel}
    />
  )
}

export default React.forwardRef<HTMLButtonElement, ButtonIconProps>(ButtonIcon)
