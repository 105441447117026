import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import LinkText from '@/components/LinkText/LinkText'
import Table, { type Column } from '@/components/Table/Table'
import CommentListExpandedRow from '@/components/common/CommentListExpandedRow/CommentListExpandedRow'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { commonCommentsColumn } from '@/constants/comments-common-columns'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useCommonCommentFilters from '@/hooks/useCommonCommentFilters'
import useComments, { type Comment } from '@/queries/useComments'
import useStudentsOptions from '@/queries/useStudentOptions'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'

import { type CommentFiltersKeys } from '../constants/comment-filters'

const routeApi = getRouteApi('/_auth/timetable/$courseId/$groupId/comments')

const CommentsView = () => {
  const { t } = useTranslation('lessonDetails')
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const { groupId } = routeApi.useParams()

  const { data: comments, isLoading } = useComments({
    groupId,
    ...search
  })

  const { data: students, isLoading: isStudentsLoading } = useStudentsOptions({
    groupId
  })

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const tableColumns: Column<Comment>[] = [
    {
      key: 'student',
      title: t('header.student'),
      width: 200,
      render: (value: Comment) => (
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.student.id
          }}
        >
          {value.student.name}
        </LinkText>
      )
    },
    ...commonCommentsColumn
  ]

  const handleChangeFilter = (
    key: CommentFiltersKeys,
    value?: string | string[] | Date
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { commonCommentsFilters } = useCommonCommentFilters({
    ...search,
    groupId,
    onChangeFilter: handleChangeFilter
  })

  const filters: Filter[] = [
    {
      label: t('label.student'),
      variant: 'multiselect',
      size: 'wide',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        loading: isStudentsLoading,
        options: students || [],
        value: search.studentsId,
        placeholder: t('label.student'),
        onChange: value => handleChangeFilter('studentsId', value)
      }
    },
    ...commonCommentsFilters
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="comments-table"
        data={comments?.list || []}
        isLoading={isLoading}
        columns={tableColumns}
        pagination={{
          count: comments?.count || 0,
          pageSize: search.pageSize,
          page: search.page
        }}
        onChangePageSize={handleChangePage}
        onChangePage={handleChangePageSize}
        expandedRowRender={record => (
          <CommentListExpandedRow comment={record} />
        )}
      />
    </>
  )
}

export default CommentsView
