import type { ReactNode } from 'react'
import { type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { type OptionContext } from 'react-select'

import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Select, { type SelectOption } from '@/components/Select/Select'
import SendActivationLinkFormField from '@/components/common/ActivationLinkFormField/SendActivationLinkFormField'
import { FormSectionFields } from '@/components/common/FormGrid/FormGrid'
import { getFullName } from '@/utils/get-full-name'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import styles from './GuardianDetailsForm.module.scss'
import type { StudentForm } from '../constants/student-payload'
import useStudentParents, { type Parent } from '../queries/useStudentParents'

type GuardianFormProps = {
  id: string
  index: number
  form: UseFormReturn<StudentForm>
  children?: ReactNode
}

const GuardianDetailsForm = (props: GuardianFormProps) => {
  const { t } = useTranslation('students')
  const { data: parents, isLoading: isParentsLoading } = useStudentParents()

  const setParent = (parent: {
    id?: string
    email: string
    phoneNumber?: string
    firstName: string
    lastName: string
  }) => {
    props.form.resetField(`guardians.${props.index}.email`)
    props.form.resetField(`guardians.${props.index}.firstName`)
    props.form.resetField(`guardians.${props.index}.lastName`)
    props.form.setValue(`guardians.${props.index}.email`, parent.email)
    props.form.setValue(`guardians.${props.index}.firstName`, parent.firstName)
    props.form.setValue(`guardians.${props.index}.lastName`, parent.lastName)
    props.form.setValue(`guardians.${props.index}.phone`, parent.phoneNumber)
    props.form.setValue(`guardians.${props.index}.id`, parent.id)
  }

  const disabledForm = !!props.form.getValues(`guardians.${props.index}.id`)

  const handleChangeParentEmail = (email: string) => {
    const selectedParent = parents?.list.find(parent => parent.email === email)

    if (selectedParent) {
      setParent(selectedParent)
    } else {
      setParent({
        email,
        id: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
      })
    }
  }

  const id = props.form.watch(`guardians.${props.index}.id`)

  return (
    <>
      <FormSectionFields>
        <FormField
          control={props.form.control}
          id={`guardian-email-${props.id}`}
          required
          label={t('label.email')}
          name={`guardians.${props.index}.email`}
          render={({ inputProps }) => (
            <Select
              {...inputProps}
              creatable
              placeholder={t('placeholder.type-email')}
              loading={isParentsLoading}
              options={parents?.options || []}
              onBlur={() => {
                inputProps.onBlur?.()
                if (!id) {
                  setTimeout(() => {
                    validateEmailUniqueness({
                      field: `guardians.${props.index}.email`,
                      form: props.form
                    })
                  }, 0)
                }
              }}
              onChange={handleChangeParentEmail}
              formatOption={(option, context) => (
                <ParentEmailOption
                  option={option}
                  parents={parents?.list || []}
                  optionContext={context}
                />
              )}
            />
          )}
        />
        <FormField
          control={props.form.control}
          id={`guardian-phone-${props.id}`}
          required
          label={t('label.phone-number')}
          name={`guardians.${props.index}.phone`}
          render={({ inputProps }) => (
            <InputPhoneNumber {...inputProps} disabled={disabledForm} />
          )}
        />
        <FormField
          control={props.form.control}
          id={`guardian-first-name-${props.id}`}
          required
          label={t('label.first-name')}
          name={`guardians.${props.index}.firstName`}
          render={({ inputProps }) => (
            <Input
              {...inputProps}
              placeholder={t('placeholder.type-first-name')}
              disabled={disabledForm}
            />
          )}
        />
        <FormField
          control={props.form.control}
          id={`guardian-last-name-${props.id}`}
          required
          label={t('label.last-name')}
          name={`guardians.${props.index}.lastName`}
          render={({ inputProps }) => (
            <Input
              {...inputProps}
              placeholder={t('placeholder.type-last-name')}
              disabled={disabledForm}
            />
          )}
        />
        <div className={styles.guardianActions}>
          <SendActivationLinkFormField
            control={props.form.control}
            name={`guardians.${props.index}.sendActivationLink`}
            id={`guardian-send-activation-link-${props.id}`}
          />
          {props.children}
        </div>
      </FormSectionFields>
    </>
  )
}

type ParentEmailOptionProps = {
  option: SelectOption<string>
  optionContext: OptionContext
  parents: Parent[]
}

const ParentEmailOption = (props: ParentEmailOptionProps) => {
  const parentOption = props.parents?.find(
    item => item.email === props.option.value
  )

  return (
    <div className={styles.parentOption}>
      <span>{props.option.label}</span>
      {parentOption && props.optionContext === 'menu' ? (
        <span className={styles.parentName}>{getFullName(parentOption)}</span>
      ) : null}
    </div>
  )
}

export default GuardianDetailsForm
