import i18n from '@/i18n'

import { USER_PROFILE } from './user-profile'

export const userProfiles = [
  {
    label: i18n.t('label.admin', { ns: 'common' }),
    value: USER_PROFILE.SUPERUSER
  },
  {
    label: i18n.t('label.teacher', { ns: 'common' }),
    value: USER_PROFILE.TEACHER
  },
  {
    label: i18n.t('label.tutor', { ns: 'common' }),
    value: USER_PROFILE.TUTOR
  },
  {
    label: i18n.t('label.support-staff', { ns: 'common' }),
    value: USER_PROFILE.SUPPORT_STAFF
  }
]
