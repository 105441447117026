import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const useNationalities = () =>
  useQuery({
    queryKey: ['panelLessonsGroupsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelUsersOptionsNationalitiesList({
          page_size: 'max'
        })
      const parsed = arrayOfOptions.parse(response.results)

      return {
        options: parsed
      }
    }
  })

export default useNationalities
