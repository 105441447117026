import { USER_PROFILE } from '@/constants/user-profile'
import { type UserProfile } from '@/types/user-profile'

export const getUserProfiles = (args: {
  profiles: UserProfile[]
  isSuperUser: boolean
  isSupportStaff: boolean
}) =>
  [
    args.isSuperUser ? USER_PROFILE.SUPERUSER : undefined,
    args.isSupportStaff ? USER_PROFILE.SUPPORT_STAFF : undefined,
    ...args.profiles
  ].filter(item => !!item)
