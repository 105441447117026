import clsx from 'clsx'
import parsePhoneNumber, {
  getCountries,
  getCountryCallingCode,
  type CountryCode,
  type PhoneNumber
} from 'libphonenumber-js/max'
import { useEffect, useState, type ChangeEvent } from 'react'
import CountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'

import Select, { type SelectOption } from '@/components/Select/Select'
import type { FormFieldType } from '@/types/form-field-type'

import styles from './InputPhoneNumber.module.scss'

type InputPhoneNumberProps = FormFieldType<string> & {
  disabled?: boolean
}

const InputPhoneNumber = (props: InputPhoneNumberProps) => {
  const { t } = useTranslation('common')

  const [phoneNumber, setPhoneNumber] = useState(
    () => pickFromPhoneNumber(props.value, 'nationalNumber') || ''
  )
  const [country, setCountry] = useState(
    () => pickFromPhoneNumber(props.value, 'country') || 'CH'
  )

  useEffect(() => {
    if (!props.value) {
      setPhoneNumber('')
      return
    }
    const parsed = parsePhoneNumber(props.value)
    if (!parsed) return
    setPhoneNumber(parsed.nationalNumber)
    if (!parsed.country) return
    setCountry(parsed.country)
  }, [props.value])

  const handleSelectChange = (value: CountryCode) => {
    setCountry(value)
    if (!phoneNumber) return
    props.onChange?.(`+${getCountryCallingCode(value)}${phoneNumber}`)
  }

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(event.target.value)
    const value = event.target.value
      ? `+${getCountryCallingCode(country)}${event.target.value}`
      : ''
    props.onChange?.(value)
  }

  return (
    <div
      className={clsx(
        styles.container,
        props.disabled && styles.disabled,
        props.invalid && styles.invalid
      )}
    >
      <CountrySelect
        id={props.id}
        onChange={handleSelectChange}
        value={country}
        disabled={props.disabled}
      />
      <input
        id={props.id}
        value={phoneNumber}
        type="tel"
        className={styles.input}
        aria-invalid={props.invalid}
        aria-describedby={props.describedby}
        onChange={handleInputChange}
        required={props.required}
        disabled={props.disabled}
        onBlur={event => props.onBlur?.(event.target.value)}
        placeholder={t('placeholder.type-phone-number')}
      />
    </div>
  )
}

const Option = (option: SelectOption<CountryCode>) => (
  <div className={styles.selectOption}>
    <span className={styles.countryFlagAndCode}>
      <CountryFlag className={styles.flagIcon} countryCode={option.value} svg />
      <span className={styles.countryCode}>
        +{getCountryCallingCode(option.value)}
      </span>
    </span>
    <span>{option.label}</span>
  </div>
)

const SelectValue = (option: SelectOption<CountryCode>) => (
  <div className={styles.selectValueContainer}>
    <CountryFlag className={styles.flagIcon} countryCode={option.value} svg />
    <span>+{getCountryCallingCode(option.value)}</span>
  </div>
)

const countries = getCountries()

type CountrySelectProps = {
  id: string
  value: CountryCode
  onChange: (value: CountryCode) => void
  disabled?: boolean
}

const CountrySelect = (props: CountrySelectProps) => {
  const { t } = useTranslation('countries')
  const options = countries.map(code => ({
    value: code,
    label: t(`names.${code}`)
  }))
  return (
    <Select
      className={styles.select}
      borderless
      id={`country-${props.id}`}
      options={options}
      value={props.value}
      onChange={props.onChange}
      formatOption={Option}
      selectValue={SelectValue}
      disabled={props.disabled}
      menuClassName={styles.selectMenu}
    />
  )
}

const pickFromPhoneNumber = <TPick extends keyof PhoneNumber>(
  phoneNumber: string | undefined,
  pick: TPick
) => phoneNumber && parsePhoneNumber(phoneNumber)?.[pick]

export default InputPhoneNumber
