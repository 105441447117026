import type {
  CommunityEnum,
  FirstLanguageEnum,
  NationalitiesEnum
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'

import {
  studentErrorsMapper,
  type StudentForm
} from '../constants/student-payload'

const useAddStudent = (options?: {
  onSuccess?: ({
    response,
    variables
  }: {
    response: { id: string }
    variables: StudentForm
  }) => void
  onError?: (validData: StudentForm) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: StudentForm) =>
      httpService.panel.panelStudentsCreate({
        email: data.email,
        phone_number: data.phone,
        first_name: data.firstName,
        last_name: data.lastName,
        ahv_number: data.ahvNumber,
        date_of_birth: formatApiDate(data.birthDate),
        gender: data.gender,
        nationalities: data.nationalities as NationalitiesEnum[],
        first_language: data.firstLanguage as FirstLanguageEnum,
        community: data.community as CommunityEnum,
        send_activation_link: data.sendActivationLink,
        parents: data.guardians.map(guardian => ({
          email: guardian.email,
          first_name: guardian.firstName,
          last_name: guardian.lastName,
          send_activation_link: guardian.sendActivationLink,
          id: guardian.id,
          phone_number: guardian.phone || undefined
        }))
      }),
    onSuccess: (response, variables) =>
      options?.onSuccess?.({ response, variables }),
    onError: (_, variables) => options?.onError?.(variables),
    fieldErrorsMapper: studentErrorsMapper
  })

export default useAddStudent
