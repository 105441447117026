import { zodResolver } from '@hookform/resolvers/zod'
import React, { useImperativeHandle } from 'react'
import { FormProvider, useForm, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import { useCountdown } from '@/hooks/useCountDown'
import useExternalErrors from '@/hooks/useExternalErrors'
import i18n from '@/i18n'
import { type FormError } from '@/types/form-error'

import PinInput from './PinInput/PinInput'
import styles from './TwoFactorForm.module.scss'
import { type TwoFactorCustomError } from '../mutations/useTwoFactor'

const CODE_LENGTH = 6

const formSchema = () =>
  z.object({
    code: z.string().length(CODE_LENGTH, {
      message: i18n.t('error.incorrect-code-length', {
        ns: 'auth',
        LENGTH: CODE_LENGTH
      })
    })
  })

type TwoFactorFormPayload = z.infer<ReturnType<typeof formSchema>>

type TwoFactorFormProps = {
  onSubmit: (payload: TwoFactorFormPayload) => void
  onBackToLogin?: () => void
  remainingAttempts?: number | null
  loading?: boolean
  errors?: FormError<TwoFactorFormPayload>[]
  customErrors?: TwoFactorCustomError[]
  formBlockedUntil?: Date | null
  children?: React.ReactNode
}

export type TwoFactorFormComponentRef = {
  form: UseFormReturn<TwoFactorFormPayload, unknown, undefined>
}

export const TwoFactorForm = (
  props: TwoFactorFormProps,
  ref: React.ForwardedRef<TwoFactorFormComponentRef>
) => {
  const { t } = useTranslation('auth')

  const form = useForm<TwoFactorFormPayload>({
    resolver: zodResolver(formSchema()),
    mode: 'onSubmit',
    defaultValues: {
      code: ''
    }
  })

  useExternalErrors(props.errors, form)

  const userLockedCountdown = useCountdown(props.formBlockedUntil?.getTime())

  const handleOnSubmit = (payload: TwoFactorFormPayload) => {
    if (!userLockedCountdown.isCounting) props.onSubmit(payload)
  }

  useImperativeHandle(
    ref,
    () => ({
      form
    }),
    [form]
  )
  const incorrectPinAndRemamingAttemptsError = props.customErrors?.find(
    e => e.code === 'remaining_attempts'
  )

  return (
    <FormProvider {...form}>
      <form
        noValidate
        className={styles.form}
        onSubmit={form.handleSubmit(handleOnSubmit)}
      >
        <div className={styles.fieldsContainer}>
          <FormField
            control={form.control}
            id="code"
            className={styles.pinField}
            name="code"
            centerError
            required
            render={({ inputProps }) => (
              <PinInput
                {...inputProps}
                disabled={userLockedCountdown.isCounting}
                onComplete={code => handleOnSubmit({ code })}
                length={CODE_LENGTH}
              />
            )}
          />
          <div className={styles.buttons}>
            <Button
              type="submit"
              loading={props.loading}
              disabled={userLockedCountdown.isCounting}
            >
              {t('button.confirm')}
            </Button>
            <Button variant="tertiary" onClick={props.onBackToLogin}>
              {t('button.back-to-login')}
            </Button>
          </div>
        </div>

        {props.children}
        {incorrectPinAndRemamingAttemptsError ? (
          <Alert
            message={incorrectPinAndRemamingAttemptsError.message}
            className={styles.alert}
            variant="warning"
          />
        ) : null}
        {userLockedCountdown.isCounting ? (
          <Alert
            message={t('help.login-will-be-unlocked', {
              TIME: userLockedCountdown.timeText
            })}
            className={styles.alert}
            variant="info"
          />
        ) : null}
      </form>
    </FormProvider>
  )
}

export default React.forwardRef<TwoFactorFormComponentRef, TwoFactorFormProps>(
  TwoFactorForm
)
