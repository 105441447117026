import { createFileRoute } from '@tanstack/react-router'

import { commentFilterSchema, CommentsView } from '@/modules/lessonDetails'

export const Route = createFileRoute(
  '/_auth/timetable/$courseId/$groupId/comments'
)({
  validateSearch: commentFilterSchema,
  component: CommentsView
})
