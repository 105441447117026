import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import Login from '@/assets/images/login.svg'
import Logo from '@/assets/images/logo.svg'

import styles from './Layout.module.scss'

type LayoutProps = {
  header: string
  subheader: React.ReactNode
  children: React.ReactNode
}

const Layout = (props: LayoutProps) => {
  const { t } = useTranslation('auth')

  useEffect(() => {
    document.body.classList.add(styles.bodyUnsetOverflow)

    return () => {
      document.body.classList.remove(styles.bodyUnsetOverflow)
    }
  }, [])

  return (
    <div className={styles.page}>
      <main className={styles.container}>
        <div className={styles.card}>
          <div className={styles.form}>
            <img
              src={Logo}
              alt={t('help.school-logo')}
              className={styles.logo}
            />
            <div className={styles.headers}>
              <h1 className={styles.header}> {props.header}</h1>
              <p className={styles.subheader}>{props.subheader}</p>
            </div>

            {props.children}
          </div>
        </div>

        <div className={styles.imageWrapper}>
          <img src={Login} className={styles.image} alt="" />
        </div>
      </main>
    </div>
  )
}

export default Layout
