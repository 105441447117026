import { createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { ParentDetailsView } from '@/modules/students'

export const Route = createFileRoute(
  '/_auth/students-and-classes/students/parents/$parentId/details'
)({
  component: ParentDetailsView,
  meta: () => [
    {
      title: i18n.t('navigation.guardian-details', { ns: 'common' })
    }
  ]
})
