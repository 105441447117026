import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import type { StringOption } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

const responseSchema = z.object({
  ids: arrayOfOptions,
  emails: arrayOfOptions,
  phone_numbers: arrayOfOptions,
  statuses: arrayOfOptions
})

export type UsersFilterOptions = {
  idOptions: StringOption[]
  emailOptions: StringOption[]
  phoneNumberOptions: StringOption[]
  statusOptions: StringOption[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): UsersFilterOptions => ({
  idOptions: response.ids,
  emailOptions: response.emails,
  phoneNumberOptions: response.phone_numbers,
  statusOptions: response.statuses
})

const useUsersFilters = () =>
  useQuery({
    queryKey: ['userFiltersList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const filtersResponse =
        await httpService.panel.panelUsersOptionsRetrieve()

      return parseResponse(responseSchema.parse(filtersResponse))
    }
  })

export default useUsersFilters
