import type { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { httpService } from '../api/http.service'
import i18n from '../i18n'

export const validateEmailUniqueness = async <
  TData extends FieldValues,
  TField extends Path<TData>
>({
  field,
  form,
  initialValue
}: {
  field: TField
  form: UseFormReturn<TData>
  initialValue?: string
}) => {
  const email = form.getValues(field)

  if (!email || email === initialValue) return

  const emailFieldState = form.getFieldState(field)
  if (emailFieldState.invalid || emailFieldState.isValidating) return

  const response = await httpService.panel.panelUsersCheckEmailTakenCreate({
    email
  })

  if (response.is_taken) {
    form.setError(field, {
      message: i18n.t('help.email-taken', { ns: 'common' })
    })
  }
}
