import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

type Params = {
  groupId?: string
}

const useCommentTypeOptions = (params: Params) =>
  useQuery({
    queryKey: ['panelCommentsOptionsCommentTypeList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelCommentsOptionsCommentTypeList({
          group_id: params.groupId
        })

      return arrayOfOptions.parse(response.results)
    }
  })

export default useCommentTypeOptions
