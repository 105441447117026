import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    first_name: requiredString(),
    last_name: requiredString(),
    email: requiredString(),
    phone_number: z.string()
  })
)

export type Parent = {
  id: string
  firstName: string
  lastName: string
  email: string
  phoneNumber?: string
}

const parseResponse = (response: z.infer<typeof responseSchema>): Parent[] =>
  response.map(item => ({
    id: item.id,
    firstName: item.first_name,
    lastName: item.last_name,
    email: item.email,
    phoneNumber: item.phone_number
  }))

const useStudentParents = () =>
  useQuery({
    queryKey: ['panelParentsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelParentsList({
        fetchKeys: {
          id: true,
          email: true,
          first_name: true,
          last_name: true,
          phone_number: true
        },
        page_size: 'max'
      })

      const parsedParents = parseResponse(
        responseSchema.parse(response.results)
      )

      return {
        list: parsedParents,
        options: parsedParents.map(parent => ({
          label: parent.email,
          value: parent.email
        }))
      }
    }
  })

export default useStudentParents
