import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Table, { type Column } from '@/components/Table/Table'
import CommentListExpandedRow from '@/components/common/CommentListExpandedRow/CommentListExpandedRow'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradebookButton from '@/components/common/GradebookButton'
import { commonCommentsColumn } from '@/constants/comments-common-columns'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useCommonCommentFilters from '@/hooks/useCommonCommentFilters'
import useComments, { type Comment } from '@/queries/useComments'
import useSemesters from '@/queries/useSemesters'
import useStudentCoursesOptions from '@/queries/useStudentCoursesOptions'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'

import { type CommentFiltersKeys } from '../constants/comment-filters'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/$studentId/comments'
)

const CommentsView = () => {
  const { t } = useTranslation('students')
  const { studentId } = routeApi.useParams()
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const { data: semesters } = useSemesters()
  const { data: comments, isLoading } = useComments({
    studentsId: [studentId],
    ...search
  })

  const { data: courses, isLoading: isCoursesLoading } =
    useStudentCoursesOptions({
      studentId
    })

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const tableColumns: Column<Comment>[] = [
    {
      key: 'course',
      title: t('header.source'),
      width: 200,
      render: (value: Comment) => value.group?.course.name ?? t('text.other')
    },
    ...commonCommentsColumn,
    {
      key: 'gradebook',
      render: (value: Comment) =>
        value.group ? (
          <GradebookButton
            courseId={value.group.course.id}
            groupId={value.group.id}
            semesterId={semesters?.currentSemester?.id}
          />
        ) : null,
      width: 160
    }
  ]

  const handleChangeFilter = (
    key: CommentFiltersKeys,
    value?: string | string[] | Date
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: value
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { commonCommentsFilters } = useCommonCommentFilters({
    ...search,
    studentId,
    onChangeFilter: handleChangeFilter
  })

  const filters: Filter[] = [
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        multiple: true,
        loading: isCoursesLoading,
        options: courses?.options || [],
        value: search.coursesId,
        placeholder: t('label.course'),
        onChange: value => handleChangeFilter('coursesId', value)
      }
    },
    ...commonCommentsFilters
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters)

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
      />
      <Table
        id="comments-table"
        data={comments?.list || []}
        isLoading={isLoading}
        columns={tableColumns}
        pagination={{
          count: comments?.count || 0,
          pageSize: search.pageSize,
          page: search.page
        }}
        onChangePageSize={handleChangePage}
        onChangePage={handleChangePageSize}
        expandedRowRender={record => (
          <CommentListExpandedRow comment={record} />
        )}
      />
    </>
  )
}

export default CommentsView
