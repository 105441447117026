import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'
import { arrayOfOptions } from '@/utils/zod'

type Params = {
  groupId?: string
  classId?: string
  studentIds?: string[]
}

const useAddedByOptions = (params: Params) =>
  useQuery({
    queryKey: ['panelCommentsOptionsAddedByList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelCommentsOptionsAddedByList({
        group_id: params.groupId,
        student_id: params.studentIds,
        class_id: params.classId
      })

      return arrayOfOptions.parse(response.results)
    }
  })

export default useAddedByOptions
