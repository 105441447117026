import { createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { StudentAddView } from '@/modules/students'

export const Route = createFileRoute(
  '/_auth/students-and-classes/students/add/'
)({
  component: StudentAddView,
  meta: () => [
    { title: i18n.t('navigation.add-new-student', { ns: 'common' }) }
  ]
})
