import { z } from 'zod'

import { commonCommentFilterSchema } from '@/constants/common-comment-filter-schema'

export const lessonDetailsCommentFilterSchema = z.object({
  studentsId: z.array(z.string()).optional()
})

export const commentFilterSchema = lessonDetailsCommentFilterSchema.merge(
  commonCommentFilterSchema
)

export type CommentFiltersKeys = keyof z.infer<typeof commentFilterSchema>
