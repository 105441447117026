import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type LabelAndValue } from '@/types/label-and-value'
import { type UserStatus } from '@/types/user-status'
import {
  arrayOfOptions,
  date,
  labelAndValue,
  requiredString,
  userStatus
} from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  full_name: requiredString(),
  year: z.string().optional(),
  student_class: z
    .object({
      id: z.string().min(1),
      name: z.string().min(1)
    })
    .nullable()
    .optional(),
  phone_number: z.string().nullable().optional(),
  email: z.string().optional(),
  tutor: z
    .object({
      id: requiredString(),
      full_name: requiredString(),
      first_name: requiredString(),
      last_name: requiredString()
    })
    .nullable()
    .optional(),
  parents: z
    .array(
      z.object({
        id: requiredString(),
        full_name: requiredString(),
        first_name: requiredString(),
        last_name: requiredString(),
        phone_number: requiredString(),
        email: requiredString(),
        status: userStatus
      })
    )
    .optional(),
  status: userStatus.optional(),
  gender: requiredString(),
  date_of_birth: date(),
  ahv_number: z.string().optional(),
  nationalities: arrayOfOptions,
  first_language: labelAndValue,
  community: labelAndValue
})

export type Parent = {
  id: string
  fullName: string
  firstName: string
  lastName: string
  phoneNumber: string
  email: string
  status: UserStatus
}

export type Student = {
  id: string
  fullName: string
  status?: UserStatus
  year?: string
  studentClass?: {
    id: string
    name: string
  }
  email?: string
  phoneNumber?: string
  gender: string
  dateOfBirth: Date
  nationalities: LabelAndValue[]
  firstLanguage: LabelAndValue
  community: LabelAndValue
  ahvNumber?: string
  tutor?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
  }
  parents?: Parent[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Student => ({
  id: response.id,
  fullName: response.full_name,
  status: response.status,
  year: response.year,
  studentClass: response.student_class
    ? {
        id: response.student_class.id,
        name: response.student_class.name
      }
    : undefined,
  email: response.email,
  phoneNumber: response.phone_number ? response.phone_number : undefined,
  gender: response.gender,
  dateOfBirth: response.date_of_birth,
  nationalities: response.nationalities,
  firstLanguage: response.first_language,
  ahvNumber: response.ahv_number,
  community: response.community,
  tutor: response.tutor
    ? {
        id: response.tutor.id,
        fullName: response.tutor.full_name,
        firstName: response.tutor.first_name,
        lastName: response.tutor.last_name
      }
    : undefined,
  parents: response.parents
    ? response.parents.map(parent => ({
        id: parent.id,
        fullName: parent.full_name,
        firstName: parent.first_name,
        lastName: parent.last_name,
        email: parent.email,
        phoneNumber: parent.phone_number,
        status: parent.status
      }))
    : []
})

export const studentQueryOptions = (id: string) => ({
  queryKey: ['panelStudentsRetrieve', id],
  staleTime: 60 * 100,
  queryFn: async () => {
    const studentResponse = await httpService.panel.panelStudentsRetrieve(id, {
      fetchKeys: {
        id: true,
        full_name: true,
        year: true,
        student_class: true,
        email: true,
        phone_number: true,
        is_active: true,
        date_of_birth: true,
        status: true,
        parents: true,
        tutor: true,
        gender: true,
        nationalities: true,
        first_language: true,
        ahv_number: true,
        community: true
      }
    })
    return parseResponse(responseSchema.parse(studentResponse))
  }
})

const useStudent = (id: string) => useQuery(studentQueryOptions(id))

export default useStudent
