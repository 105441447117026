import { z } from 'zod'

import { commentType } from '@/utils/zod'

import DEFAULT_PAGE_SIZE from './default-page-size'

export const commonCommentFilterSchema = z.object({
  addedById: z.array(z.string()).optional(),
  dateAfter: z.string().optional(),
  dateBefore: z.string().optional(),
  commentType: z.array(commentType).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type CommonCommentFilterSchema = keyof z.infer<
  typeof commonCommentFilterSchema
>
