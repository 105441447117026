import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@/assets/icons/check.svg?react'
import CloseIcon from '@/assets/icons/close.svg?react'

import styles from './PasswordRequirements.module.scss'
import {
  hasLargeLetter,
  hasSmallLetter,
  hasNumber,
  hasSpecialSign,
  has8Characters,
  fulfilledRequirementsCount
} from '../utils/password-requirements'

type PasswordRequirementsProps = {
  password: string
  id: string
}

type RuleProps = { text: string; isFulfilled: boolean; id: string }

const Rule = (props: RuleProps) => {
  const { t } = useTranslation('auth')

  return (
    <li
      className={clsx(styles.rule, { [styles.successRule]: props.isFulfilled })}
      aria-label={props.text}
    >
      {props.isFulfilled ? (
        <CheckIcon
          className={styles.icon}
          aria-label={t('help.check-mark')}
          aria-describedby={props.id}
        />
      ) : (
        <CloseIcon
          className={styles.icon}
          aria-label={t('help.x-mark')}
          aria-describedby={props.id}
        />
      )}
      <span id={props.id}>{props.text}</span>
    </li>
  )
}

const PasswordRequirements = (props: PasswordRequirementsProps) => {
  const { t } = useTranslation(['auth'])

  const progressCount = fulfilledRequirementsCount(props.password)

  return (
    <div className={styles.container} id={props.id}>
      <label className={styles.text} htmlFor="checklist">
        {t('header.rules-list-header')}
      </label>
      <ul className={styles.list} id="checklist">
        <Rule
          isFulfilled={hasLargeLetter(props.password)}
          text={t('help.one-large-letter-rule')}
          id="constainsLargeLetter"
        />
        <Rule
          isFulfilled={hasSmallLetter(props.password)}
          text={t('help.one-small-letter-rule')}
          id="constainsSmallLetter"
        />
        <Rule
          isFulfilled={hasNumber(props.password)}
          text={t('help.one-number-rule')}
          id="constainsNumber"
        />
        <Rule
          isFulfilled={hasSpecialSign(props.password)}
          text={t('help.one-special-sign-rule')}
          id="constainsSpecialSign"
        />
        <Rule
          isFulfilled={has8Characters(props.password)}
          text={t('help.enough-long-rule')}
          id="minLength"
        />
      </ul>
      <VisuallyHidden.Root>
        <p aria-live="polite">
          {t('help.rules-completed', { NUMBER: progressCount })}
        </p>
      </VisuallyHidden.Root>
    </div>
  )
}
export default PasswordRequirements
