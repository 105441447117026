import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import type { DropdownMenuItemProps as DropdownMenuItemRadixProps } from '@radix-ui/react-dropdown-menu'
import clsx from 'clsx'
import React from 'react'

import styles from './Dropdown.module.scss'

type BaseDropdownProps = {
  variant?: 'default' | 'inverted'
  trigger: React.ReactNode
  className?: string
  children: React.ReactNode
  open?: boolean
  sideOffset?: number
  onOpenChange?: (value: boolean) => void
  onContentCloseAutoFocus?: (event: Event) => void
  contentHidden?: boolean
}

type AlignProps =
  | { align?: 'center' | undefined; alignOffset?: never }
  | { align: 'start' | 'end'; alignOffset?: number }

type DropdownProps = BaseDropdownProps & AlignProps

const Dropdown = (props: DropdownProps) => {
  const { variant = 'default' } = props

  return (
    <DropdownMenu.Root open={props.open} onOpenChange={props.onOpenChange}>
      <DropdownMenu.Trigger asChild>{props.trigger}</DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        <DropdownMenu.Content
          onInteractOutside={() => {
            props.onOpenChange?.(false)
          }}
          className={clsx(
            props.className,
            styles.container,
            props.contentHidden && styles.hidden,
            styles[variant]
          )}
          sideOffset={props.sideOffset || 4}
          align={props.align}
          alignOffset={props.alignOffset}
          onCloseAutoFocus={props.onContentCloseAutoFocus}
          hidden={props.contentHidden}
        >
          {props.children}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
type DropdownMenuItemProps = DropdownMenuItemRadixProps & {
  variant?: 'neutral' | 'danger' | 'active'
}

export const DropdownMenuItem = React.forwardRef<
  HTMLDivElement,
  DropdownMenuItemProps
>((props: DropdownMenuItemProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  const { variant, children, className, ...innerProps } = props
  return (
    <DropdownMenu.DropdownMenuItem
      ref={ref}
      className={clsx(
        className,
        styles.item,
        variant === 'danger' && styles.itemDanger,
        variant === 'active' && styles.itemActive
      )}
      {...innerProps}
    >
      {children}
    </DropdownMenu.DropdownMenuItem>
  )
})

DropdownMenuItem.displayName = 'DropdownMenuItem'

export default Dropdown
