import { zodResolver } from '@hookform/resolvers/zod'
import {
  type DefaultValues,
  useForm,
  type UseFormReturn
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import DatePicker from '@/components/DatePicker/DatePicker'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputPhoneNumber from '@/components/InputPhoneNumber/InputPhoneNumber'
import Loading from '@/components/Loading'
import Select from '@/components/Select/Select'
import SendActivationLinkFormField from '@/components/common/ActivationLinkFormField/SendActivationLinkFormField'
import {
  FormButtons,
  FormLayout,
  FormSection
} from '@/components/common/FormGrid/FormGrid'
import genderOptions from '@/constants/gender-options'
import useExternalErrors from '@/hooks/useExternalErrors'
import useCommunities from '@/queries/useCommunities'
import useLanguages from '@/queries/useLanguages'
import useNationalities from '@/queries/useNationalities'
import type { FormError } from '@/types/form-error'
import type { UserStatus } from '@/types/user-status'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import AcademicInformation from './AcademicInformation'
import styles from './StudentForm.module.scss'
import StudentGuardiansForm from './StudentGuardiansForm'
import {
  studentFormSchema,
  type StudentForm
} from '../constants/student-payload'

type StudentFormProps = {
  onSubmit: (data: StudentForm) => void
  defaultValues: DefaultValues<StudentForm>
  formErrors?: FormError<StudentForm>[]
  userStatus?: UserStatus
  loading: boolean
}

const StudentForm = (props: StudentFormProps) => {
  const form = useForm<StudentForm>({
    resolver: zodResolver(studentFormSchema),
    mode: 'onBlur',
    defaultValues: props.defaultValues
  })

  useExternalErrors(props.formErrors, form)

  return (
    <Loading spinning={props.loading}>
      <FormLayout form={form} onSubmit={props.onSubmit}>
        <BasicInformationFormFields form={form} userStatus={props.userStatus} />
        <ContactDetails form={form} defaultValues={props.defaultValues} />
        <AcademicInformation form={form} />
        <StudentGuardiansForm form={form} />
        <FormButtons />
      </FormLayout>
    </Loading>
  )
}

type ContactDetailsProps = {
  form: UseFormReturn<StudentForm>
  defaultValues: DefaultValues<StudentForm>
}

const ContactDetails = (props: ContactDetailsProps) => {
  const { t } = useTranslation('students')
  return (
    <FormSection header={t('header.contact-details')}>
      <FormField
        control={props.form.control}
        id="email"
        required
        label={t('label.email')}
        name="email"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            onBlur={() => {
              inputProps.onBlur?.()
              setTimeout(() => {
                validateEmailUniqueness({
                  field: 'email',
                  form: props.form,
                  initialValue: props.defaultValues.email
                })
              }, 0)
            }}
            placeholder={t('placeholder.type-email')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="phone-number"
        label={t('label.phone-number')}
        name="phone"
        render={({ inputProps }) => <InputPhoneNumber {...inputProps} />}
      />
    </FormSection>
  )
}

const BasicInformationFormFields = (props: {
  form: UseFormReturn<StudentForm>
  userStatus?: UserStatus
}) => {
  const { t } = useTranslation('common')

  const { data: nationalities, isLoading: isNationalitiesLoading } =
    useNationalities()
  const { data: firstLanguages, isLoading: isFirstLanguagesLoading } =
    useLanguages()
  const { data: communities, isLoading: isCommunitiesLoading } =
    useCommunities()

  return (
    <FormSection header={t('header.basic-information')}>
      <FormField
        control={props.form.control}
        id="first-name"
        required
        label={t('label.first-name')}
        name="firstName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-first-name')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="last-name"
        required
        label={t('label.last-name')}
        name="lastName"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-last-name')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="avh-number"
        required
        label={t('label.ahv-number')}
        name="ahvNumber"
        render={({ inputProps }) => (
          <Input
            {...inputProps}
            placeholder={t('placeholder.type-ahv-number')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="birth-date"
        label={t('label.birth-date')}
        name="birthDate"
        required
        render={({ inputProps }) => (
          <DatePicker {...inputProps} className={styles.birthDate} />
        )}
      />
      <FormField
        control={props.form.control}
        id="gender"
        label={t('label.gender')}
        name="gender"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            placeholder={t('placeholder.select-gender')}
            options={genderOptions}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="nationality"
        label={t('label.nationality')}
        name="nationalities"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            options={nationalities?.options || []}
            loading={isNationalitiesLoading}
            multiple
            placeholder={t('placeholder.select-nationality')}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="first-language"
        label={t('label.first-language')}
        name="firstLanguage"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            placeholder={t('placeholder.select-language')}
            options={firstLanguages?.options || []}
            loading={isFirstLanguagesLoading}
          />
        )}
      />
      <FormField
        control={props.form.control}
        id="community"
        label={t('label.community')}
        name="community"
        render={({ inputProps }) => (
          <Select
            {...inputProps}
            options={communities?.options || []}
            loading={isCommunitiesLoading}
            placeholder={t('placeholder.select-residential-community')}
          />
        )}
      />
      <SendActivationLinkFormField
        control={props.form.control}
        id="send-activation-link"
        name="sendActivationLink"
        userStatus={props.userStatus}
      />
    </FormSection>
  )
}

export default StudentForm
