import { useTranslation } from 'react-i18next'

import TextWithLabel from '@/components/TextWithLabel/TextWithLabel'
import { type Comment } from '@/queries/useComments'

import styles from './CommentListExpandedRow.module.scss'

type CommentListExpandedRowProps = {
  comment: Comment
}

const CommentListExpandedRow = (props: CommentListExpandedRowProps) => {
  const { t } = useTranslation('common')

  return (
    <div className={styles.wrapper}>
      <TextWithLabel label={t('label.comment')} text={props.comment.content} />
    </div>
  )
}

export default CommentListExpandedRow
