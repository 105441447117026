import { getRouteApi } from '@tanstack/react-router'
import { isFuture, isPast } from 'date-fns'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { type Route } from '@/components/Tabs/Tabs'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import useCourse from '@/queries/useCourse'
import useGroup from '@/queries/useGroup'

const routeApi = getRouteApi('/_auth/timetable/$courseId/$groupId')

const CourseView = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation('lessonDetails')
  const { courseId, groupId } = routeApi.useParams()

  const { data: group } = useGroup(groupId)
  const { data: course } = useCourse(courseId)
  const search = routeApi.useSearch()
  const currentSemester = group?.semesters.find(
    semester => isPast(semester.startDate) && isFuture(semester.endDate)
  )

  const routes: Route[] = [
    {
      title: t('navigation.lesson'),
      link: {
        to: '/timetable/$courseId/$groupId/lesson-details',
        search
      }
    },
    {
      title: t('navigation.grades'),
      link: {
        to: '/timetable/$courseId/$groupId/grades',
        search: {
          semesterId: currentSemester?.id || group?.semesters[0].id,
          ...search
        }
      }
    },
    {
      title: t('navigation.assignments'),
      link: {
        to: '/timetable/$courseId/$groupId/assignments',
        search
      }
    },
    {
      title: t('navigation.comments'),
      link: {
        to: '/timetable/$courseId/$groupId/comments',
        search
      }
    }
  ]

  return (
    <BasicLayout
      header={course?.name}
      routes={routes}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      {children}
    </BasicLayout>
  )
}
export default CourseView
