import { useTranslation } from 'react-i18next'

import WarningCircle from '@/assets/icons/warning-circle.svg?react'
import Alert from '@/components/Alert/Alert'
import Label from '@/components/Label'
import RadioGroup from '@/components/Radio/RadioGroup'
import CoverTeacherField from '@/components/common/CoverSettingModal/CoverTeacherField'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import useTeachersAvailability from '@/queries/useTeachersAvailability'
import { type CoverCancelLesson } from '@/types/lesson'
import { formatTime } from '@/utils/format-date'
import { getLessonFormattedTime } from '@/utils/lesson-details-format'

import styles from './Lesson.module.scss'

export type LessonStatus = 'cancel' | 'cover' | 'no-changes'

type TeacherType = 'teacherCover' | 'coTeacherCover'

type LessonProps = {
  isFormValidated?: boolean
  lesson: CoverCancelLesson
  onStatusChange: (value: LessonStatus) => void
  onTeacherChange: (data: {
    teacher?: { id: string; name: string }
    teacherType: TeacherType
  }) => void
}

const Lesson = (props: LessonProps) => {
  const { t } = useTranslation(['coveredCancelledLessons', 'common'])

  const status = props.lesson.isCancelled
    ? 'cancel'
    : props.lesson.isCovered
      ? 'cover'
      : 'no-changes'

  const { data: teachers, isPending: isTeacherOptionsLoading } =
    useTeachersAvailability({
      startDate: props.lesson.startDate,
      endDate: props.lesson.endDate,
      enabled: status === 'cover',
      ignoreLessonId: [props.lesson.id]
    })

  const variantOptions = [
    {
      label: t('label.cover'),
      value: 'cover'
    },
    {
      label: t('label.cancel'),
      value: 'cancel',
      disabled: !props.lesson.canBeCancelled
    },
    { label: t('label.no-changes'), value: 'no-changes' }
  ]

  const { isMobile } = useScreenResolution()

  const handleChangeTeacher = ({
    teacherId,
    type
  }: {
    teacherId?: string
    type: TeacherType
  }) => {
    const teacherName = teachers?.list.find(
      teacher => teacher.id === teacherId
    )?.name

    props.onTeacherChange({
      teacherType: type,
      teacher:
        teacherId && teacherName
          ? {
              id: teacherId,
              name: teacherName
            }
          : undefined
    })
  }

  const teacherInvalid =
    props.isFormValidated &&
    !props.lesson.teacherCover?.id &&
    !props.lesson.coTeacherCover?.id

  const coTeacherInvalid =
    props.isFormValidated &&
    !props.lesson.coTeacherCover?.id &&
    !props.lesson.teacherCover?.id &&
    !!props.lesson.coTeacher

  return (
    <div className={styles.lesson}>
      <div>
        <div className={styles.detailsWrapper}>
          <span className={styles.details}>
            {`${formatTime(props.lesson.startDate)} - ${formatTime(props.lesson.startDate)} ${props.lesson.course.name}`}
          </span>
          <span className={styles.group}>{props.lesson.group.name}</span>
        </div>

        <RadioGroup
          ariaLabel={t('label.manage-lesson', {
            COURSE: props.lesson.course.name,
            GROUP: props.lesson.group.name,
            DATE: getLessonFormattedTime(props.lesson)
          })}
          orientation={isMobile ? 'vertical' : 'horizontal'}
          id={props.lesson.id + 'radio-button'}
          value={status}
          options={variantOptions}
          onChange={props.onStatusChange}
        />
      </div>

      {!props.lesson.canBeCancelled ? (
        <Alert
          message={t('text.lesson-can-not-be-cancelled')}
          variant="warning"
          withoutBackground
        />
      ) : null}

      {status === 'cover' ? (
        <>
          <div>
            <span className={styles.teacherlabel}>
              {t('header.main-teacher')}
            </span>

            <Label id="teacher" label={t('label.select-teacher')} />
            <CoverTeacherField
              placeholder={t('text.select-teacher')}
              teacher={props.lesson.teacher}
              onClear={() => {
                props.onTeacherChange({
                  teacherType: 'teacherCover',
                  teacher: undefined
                })
              }}
              inputProps={{
                id: props.lesson.id + '-teacher-select',
                value: props.lesson.teacherCover?.id || undefined,
                invalid: teacherInvalid,
                onChange: value =>
                  handleChangeTeacher({
                    teacherId: value,
                    type: 'teacherCover'
                  })
              }}
              teacherList={teachers?.list || []}
              options={teachers?.options || []}
              isOptionsLoading={isTeacherOptionsLoading}
            />
          </div>

          {props.lesson.coTeacher ? (
            <div>
              <span className={styles.teacherlabel}>
                {t('header.co-teacher')}
              </span>
              <Label id="teacher" label={t('label.select-teacher')} />
              <CoverTeacherField
                placeholder={t('text.select-co-teacher')}
                teacher={props.lesson.coTeacher}
                onClear={() => {
                  props.onTeacherChange({
                    teacherType: 'coTeacherCover',
                    teacher: undefined
                  })
                }}
                inputProps={{
                  id: props.lesson.id + '-co-teacher-select',
                  value: props.lesson.coTeacherCover?.id || undefined,
                  invalid: coTeacherInvalid,
                  onChange: value =>
                    handleChangeTeacher({
                      teacherId: value,
                      type: 'coTeacherCover'
                    })
                }}
                teacherList={teachers?.list || []}
                options={teachers?.options || []}
                isOptionsLoading={isTeacherOptionsLoading}
              />
            </div>
          ) : null}

          {teacherInvalid && coTeacherInvalid ? (
            <div className={styles.errorContainer}>
              <WarningCircle className={styles.errorIcon} />
              {t('common:error.one-field-is-required')}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  )
}

export default Lesson
