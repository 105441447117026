import { useBlocker, useRouter } from '@tanstack/react-router'
import { useState, type ReactNode } from 'react'
import {
  FormProvider,
  type UseFormReturn,
  type FieldValues
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'

import styles from './FormGrid.module.scss'
import DirtyModal from '../DirtyModal'

type FormLayoutProps<TFormValues extends FieldValues> = {
  form: UseFormReturn<TFormValues>
  onSubmit: (data: TFormValues) => void
  children: ReactNode
}

export const FormLayout = <TFormValues extends FieldValues>(
  props: FormLayoutProps<TFormValues>
) => {
  const [isFormSubmitted, setIsFormSubmitted] = useState(false)
  const {
    proceed,
    reset,
    status: blockerStatus
  } = useBlocker({
    condition: props.form.formState.isDirty && !isFormSubmitted
  })

  const handleSubmit = (data: TFormValues) => {
    setIsFormSubmitted(true)
    props.onSubmit(data)
  }

  return (
    <>
      <FormProvider {...props.form}>
        <form
          noValidate
          className={styles.form}
          onSubmit={props.form.handleSubmit(handleSubmit)}
        >
          {props.children}
        </form>
      </FormProvider>
      {blockerStatus === 'blocked' && (
        <DirtyModal isOpen onConfirm={proceed} onCancel={reset} />
      )}
    </>
  )
}

export const FormSection = (props: {
  children: ReactNode
  header: ReactNode
}) => (
  <FormSectionFields>
    <FormSectionHeader>{props.header}</FormSectionHeader>
    {props.children}
  </FormSectionFields>
)

export const FormSectionHeader = (props: { children: ReactNode }) => (
  <h2 className={styles.sectionHeader}>{props.children}</h2>
)

export const FormSectionFields = (props: { children: ReactNode }) => (
  <div className={styles.formContainer}>{props.children}</div>
)

export const FormButtons = () => {
  const { t } = useTranslation('common')
  const router = useRouter()

  const handleCancel = () => {
    router.history.back()
  }

  return (
    <div className={styles.buttons}>
      <Button variant="secondary" onClick={handleCancel}>
        {t('button.cancel')}
      </Button>
      <Button type="submit">{t('button.save')}</Button>
    </div>
  )
}
