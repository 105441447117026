import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import { getFullName } from '@/utils/get-full-name'

import UserForm, { type UserForm as UserFormType } from '../components/UserForm'
import { type AddUserPayload } from '../constants/user-payload'
import useAddUser from '../mutations/useAddUser'

const defaultValues: UserFormType = {
  email: '',
  profile: [],
  sendActivationLink: false,
  firstName: '',
  lastName: '',
  ahvNumber: '',
  birthDate: undefined,
  firstLanguage: undefined,
  nationalities: [],
  gender: undefined,
  community: undefined,
  phoneNumber: '',
  emergencyPhoneNumber: '',
  classes: [],
  preferredRooms: []
}

const routeApi = getRouteApi('/_auth/users/add/')

const UserAddView = () => {
  const { t } = useTranslation(['users', 'common'])
  const navigate = routeApi.useNavigate()

  const {
    mutate: addUser,
    isPending,
    formErrors
  } = useAddUser({
    onSuccess: ({ response, variables }) => {
      if (variables.sendActivationLink) {
        toast({
          variant: 'success',
          title: t('toast.successfully-send-activation-link')
        })
      }
      toast({
        variant: 'success',
        title: t('toast.add-user', {
          NAME: getFullName(variables)
        })
      })
      if (!response?.id) return
      navigate({
        to: '/users/$userId/details',
        params: {
          userId: response?.id
        }
      })
    },
    onError: data => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-add', {
          NAME: getFullName(data)
        })
      })
    }
  })

  const handleSubmit = (payload: AddUserPayload) => {
    addUser(payload)
  }

  return (
    <BasicLayout
      header={t('header.add-new-user')}
      moduleName={t('common:header.administration')}
    >
      <UserForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
        loading={isPending}
        formErrors={formErrors}
        canRemoveTeacher
        canRemoveTutor
      />
    </BasicLayout>
  )
}

export default UserAddView
