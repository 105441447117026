import { zodResolver } from '@hookform/resolvers/zod'
import { getRouteApi } from '@tanstack/react-router'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import NonFieldErrors from '@/components/common/NonFieldErrors'
import { useCountdown } from '@/hooks/useCountDown'
import useExternalErrors from '@/hooks/useExternalErrors'
import i18n from '@/i18n'
import type { FormError } from '@/types/form-error'
import { email, requiredStringWithLabel } from '@/utils/zod'

import styles from './LoginForm.module.scss'

const routeApi = getRouteApi('/login')

const formSchema = () =>
  z.object({
    email: email(i18n.t('label.email', { ns: 'auth' })),
    password: requiredStringWithLabel(i18n.t('label.password', { ns: 'auth' }))
  })

export type LoginFormPayload = z.infer<ReturnType<typeof formSchema>>

type LoginFormProps = {
  onLogin: (payload: LoginFormPayload) => void
  loading?: boolean
  errors?: FormError<LoginFormPayload>[]
  formBlockedUntil?: Date | null
  isUserBlocked?: boolean
}

export const LoginForm = (props: LoginFormProps) => {
  const { t } = useTranslation(['auth'])

  const { isCounting, timeText } = useCountdown(
    props.formBlockedUntil?.getTime()
  )

  const search = routeApi.useSearch()

  const form = useForm<LoginFormPayload>({
    resolver: zodResolver(formSchema()),
    mode: 'onTouched',
    defaultValues: {
      email: '',
      password: ''
    }
  })

  useExternalErrors(props.errors, form)

  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={form.handleSubmit(props?.onLogin)}
        className={styles.formContainer}
      >
        <div className={styles.fieldsContainer}>
          <FormField
            control={form.control}
            id="email"
            label={t('label.email')}
            name="email"
            render={({ inputProps }) => (
              <Input
                placeholder={t('label.email')}
                type="email"
                {...inputProps}
              />
            )}
          />
          <FormField
            control={form.control}
            id="password"
            label={t('label.password')}
            name="password"
            render={({ inputProps }) => (
              <Input
                placeholder={t('label.password')}
                {...inputProps}
                type="password"
              />
            )}
          />
          <Button
            asLink
            to="/reset-password"
            search={{ redirect: search.redirect }}
            className={styles.link}
            variant="tertiary"
            dataTestId="forgot-password-button"
          >
            {t('button.forgot-password')}
          </Button>
        </div>

        <Button
          loading={props.loading}
          type="submit"
          disabled={props.loading}
          className={styles.loginButton}
        >
          {t('button.login')}
        </Button>
        <div className={styles.alertsContainer}>
          <NonFieldErrors
            error={form.formState.errors.root}
            dataTestId="login-error"
          />

          {isCounting ? (
            <Alert
              message={t('error.account-locked', {
                TIME: timeText
              })}
              variant="error"
              dataTestId="account-locked-error"
            />
          ) : null}

          {props.isUserBlocked ? (
            <Alert message={t('error.account-blocked')} variant="error" />
          ) : null}
        </div>
      </form>
    </FormProvider>
  )
}

export default LoginForm
