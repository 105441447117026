import clsx from 'clsx'
import { groupBy, zip } from 'lodash'
import { useTranslation } from 'react-i18next'

import InfoCircle from '@/assets/icons/info-circle.svg?react'
import LessonCancelIcon from '@/assets/icons/lesson-cancel.svg?react'
import LessonCoverIcon from '@/assets/icons/lesson-cover.svg?react'
import LessonUnchangesIcon from '@/assets/icons/lesson-unchanges.svg?react'
import Alert from '@/components/Alert/Alert'
import DataCard from '@/components/DataCard/DataCard'
import Tooltip from '@/components/Tooltip/Tooltip'
import { type CoverCancelLesson } from '@/types/lesson'
import { formatDate, formatTime } from '@/utils/format-date'
import { getLessonFormattedTime } from '@/utils/lesson-details-format'

import styles from './Summary.module.scss'
import useConflicts from '../hooks/useConflicts'

type SummaryProps = {
  className?: string
  lessons?: CoverCancelLesson[]
  previousLessons?: CoverCancelLesson[]
  hideHeader?: boolean
}

const Summary = (props: SummaryProps) => {
  const { t } = useTranslation('coveredCancelledLessons')

  const groupedLessons = groupBy(
    props.lessons
      ?.filter(lesson => lesson.comment)
      .map(lesson => ({
        ...lesson,
        date: formatDate(lesson.startDate)
      })),
    'date'
  )

  const coveredLessonsCount = props.lessons?.filter(
    lesson => lesson.isCovered
  ).length

  const cancelledLessonsCount = props.lessons?.filter(
    lesson => lesson.isCancelled
  ).length

  const unchangedLessonsCount = props.lessons?.filter(
    lesson => !lesson.isCancelled && !lesson.isCovered
  ).length

  const isCoverCancelLessonCommentExist = props.lessons?.some(
    lesson => !!lesson.comment
  )

  return (
    <div>
      {!props.hideHeader ? (
        <p className={styles.summaryHeader}>{t('header.summary')}</p>
      ) : null}
      <div className={clsx(styles.summary, props.className)}>
        <div className={styles.wrapper}>
          <div className={styles.dataCardWrapper}>
            <DataCard
              label={t('label.covered-lessons')}
              count={coveredLessonsCount}
              icon={<LessonCoverIcon className={styles.lessonCoverIcon} />}
            />
            <DataCard
              label={t('label.cancelled-lessons')}
              count={cancelledLessonsCount}
              icon={<LessonCancelIcon className={styles.lessonCancelIcon} />}
            />
            <DataCard
              label={t('label.unchanged-lessons')}
              count={unchangedLessonsCount}
              icon={
                <LessonUnchangesIcon className={styles.lessonUnchagesIcon} />
              }
            />
          </div>
        </div>

        <Conflicts
          lessons={props.lessons}
          previousLessons={props.previousLessons}
        />

        <div className={styles.wrapper}>
          <p className={styles.header}>
            {t('header.old-comments')}
            <Tooltip
              text={t('text.old-comments')}
              renderTriggerAsChild
              trigger={
                <button aria-label={t('text.info-icon')}>
                  <InfoCircle className={styles.icon} />
                </button>
              }
            />
          </p>

          {isCoverCancelLessonCommentExist ? (
            Object.entries(groupedLessons).map(([date, lessonsList]) => (
              <div key={date} className={styles.dayWrapper}>
                <span className={styles.day}>{date}</span>
                {lessonsList.map(lesson => (
                  <div key={lesson.id}>
                    <span className={styles.details}>
                      {`${formatTime(lesson.startDate)} - ${formatTime(lesson.startDate)} ${lesson.course.name}`}
                    </span>
                    <span className={styles.comment}>{lesson.comment}</span>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <span className={styles.noData}> {t('text.no-comments')}</span>
          )}
        </div>
      </div>
    </div>
  )
}

type ConfictWithTeacher = {
  lesson: CoverCancelLesson
  key: string
  teacherName?: string
}

const Conflicts = (props: {
  lessons?: CoverCancelLesson[]
  previousLessons?: CoverCancelLesson[]
}) => {
  const { t } = useTranslation('coveredCancelledLessons')

  const {
    lessonsWithAttendanceChecked,
    conflictsCount,
    teachersAvailability,
    coTeachersAvailability,
    lessonWithTeacherBusy,
    lessonWithTeacherUnvailable
  } = useConflicts({
    lessons: props.lessons,
    previousLessons: props.previousLessons
  })

  const lessonWithTeacherAvailability = zip(
    props.lessons || [],
    teachersAvailability,
    coTeachersAvailability
  )

  const conflictWithBusyTeachers: ConfictWithTeacher[] = []
  const conflictWithUnavailableTeachers: ConfictWithTeacher[] = []

  lessonWithTeacherAvailability?.forEach(
    ([lesson, teacherAvailability, coTeacherAvailability]) => {
      if (!lesson) return

      if (teacherAvailability?.data?.status === 'busy') {
        conflictWithBusyTeachers.push({
          lesson,
          key: lesson.id + 'teacher-busy',
          teacherName: teacherAvailability.data.name
        })
      } else if (teacherAvailability?.data?.status === 'unavailable') {
        conflictWithUnavailableTeachers.push({
          lesson,
          key: lesson.id + 'teacher-unavailable',
          teacherName: teacherAvailability.data.name
        })
      }

      if (coTeacherAvailability?.data?.status === 'busy') {
        conflictWithBusyTeachers.push({
          lesson,
          key: lesson.id + 'coTeacher-busy',
          teacherName: coTeacherAvailability.data.name
        })
      } else if (coTeacherAvailability?.data?.status === 'unavailable') {
        conflictWithUnavailableTeachers.push({
          lesson,
          key: lesson.id + 'coTeacher-unavailable',
          teacherName: coTeacherAvailability.data.name
        })
      }
    }
  )

  return (
    <div className={styles.wrapper}>
      <p className={styles.header}>
        {t('header.warnings')} ({conflictsCount})
      </p>

      {!props.previousLessons ? (
        <span className={styles.noData}>
          {t('text.select-teacher-and-dates')}
        </span>
      ) : conflictsCount ? (
        <Alert
          variant="warning"
          message={
            <div className={styles.conflicts}>
              {lessonWithTeacherBusy ? (
                <ConflictsBlock
                  header={t('label.teacher-busy')}
                  list={conflictWithBusyTeachers}
                />
              ) : null}

              {lessonWithTeacherUnvailable ? (
                <ConflictsBlock
                  header={t('label.teacher-unavailable')}
                  list={conflictWithUnavailableTeachers}
                />
              ) : null}

              {lessonsWithAttendanceChecked.length ? (
                <ConflictsBlock
                  header={t('label.attendance-already-checked')}
                  list={lessonsWithAttendanceChecked.map(lesson => ({
                    key: lesson.id,
                    lesson
                  }))}
                />
              ) : null}
            </div>
          }
        />
      ) : (
        <Alert variant="success" message={t('text.no-conflicts')} />
      )}
    </div>
  )
}

type ConflictsBlockProps = {
  header: string
  list: ConfictWithTeacher[]
}

const ConflictsBlock = (props: ConflictsBlockProps) => (
  <div>
    <span>{props.header}</span>

    <ul>
      {props.list.map(item => (
        <ConflictItem
          key={item.key}
          lesson={item.lesson}
          teacherName={item.teacherName}
        />
      ))}
    </ul>
  </div>
)

type ConflictItemProps = {
  lesson: CoverCancelLesson
  teacherName?: string
}
const ConflictItem = (props: ConflictItemProps) => (
  <li className={styles.conflictItem}>
    {props.teacherName ? (
      <span className={styles.teacherName}>{`${props.teacherName} `}</span>
    ) : null}
    {`${getLessonFormattedTime(props.lesson)} ${props.lesson.course.name}`}
  </li>
)

export default Summary
