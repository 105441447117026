import type { Path } from 'react-hook-form'
import { z } from 'zod'

import type { FieldErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import { email, gender, phone, requiredStringWithLabel } from '@/utils/zod'

export const guardianSchema = z.object({
  id: z.string().optional(),
  email: email(i18n.t('label.email', { ns: 'students' })),
  phone: phone().optional(),
  firstName: requiredStringWithLabel(
    i18n.t('label.first-name', { ns: 'students' })
  ),
  lastName: requiredStringWithLabel(
    i18n.t('label.last-name', { ns: 'students' })
  ),
  sendActivationLink: z.boolean()
})

export type GuardianForm = z.infer<typeof guardianSchema>

export const studentFormSchema = z.object({
  firstName: requiredStringWithLabel(
    i18n.t('label.first-name', { ns: 'users' })
  ),
  lastName: requiredStringWithLabel(i18n.t('label.last-name', { ns: 'users' })),
  ahvNumber: requiredStringWithLabel(
    i18n.t('label.ahv-number', { ns: 'users' })
  ),
  birthDate: z.date(),
  gender: gender().optional(),
  nationalities: z.array(z.string()),
  firstLanguage: z.string().optional(),
  community: z.string().optional(),
  email: email(i18n.t('label.email', { ns: 'students' })),
  phone: z.string(),
  sendActivationLink: z.boolean(),
  year: z.string().optional(),
  class: z.string().optional(),
  guardians: z.array(guardianSchema)
})

export type StudentForm = z.infer<typeof studentFormSchema>

export const studentErrorsMapper: FieldErrorsMapper<StudentForm> = attr => {
  const studentFieldMap: Record<string, Path<StudentForm>> = {
    email: 'email',
    phone_number: 'phone',
    first_name: 'firstName',
    last_name: 'lastName',
    ahv_number: 'ahvNumber',
    date_of_birth: 'birthDate',
    gender: 'gender',
    nationalities: 'nationalities',
    first_language: 'firstLanguage',
    community: 'community',
    send_activation_link: 'sendActivationLink'
  }
  const mappedStudentField = studentFieldMap[attr]
  if (mappedStudentField) return mappedStudentField

  const [fieldName, index, key] = attr.split('.')
  if (fieldName === 'parents') {
    const guardianFieldMap: Record<string, Path<GuardianForm>> = {
      email: 'email',
      first_name: 'firstName',
      last_name: 'lastName',
      send_activation_link: 'sendActivationLink',
      phone_number: 'phone'
    }
    const mappedGuardianField = guardianFieldMap[key]
    if (mappedGuardianField)
      return `guardians.${Number(index)}.${mappedGuardianField}`
  }

  return 'root'
}
