import { getRouteApi } from '@tanstack/react-router'
import { capitalize } from 'lodash'
import { useTranslation } from 'react-i18next'

import EditIcon from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import DataBlock from '@/components/DataBlock/DataBlock'
import LinkText from '@/components/LinkText/LinkText'
import { USER_PROFILE } from '@/constants/user-profile'
import useAuthStore from '@/store/useAuthStore'
import { formatDate, getWeekDays } from '@/utils/format-date'

import styles from './UserDetailsView.module.scss'
import useUser, { type Schedule } from '../queries/useUser'

const routeApi = getRouteApi('/_auth/users/$userId/details')

const UserDetailsView = () => {
  const { userId } = routeApi.useParams()
  const { data: user } = useUser(userId)
  const { user: userStore } = useAuthStore()
  const { t } = useTranslation(['users'])

  const getScheduleText = (value?: Schedule[]) =>
    value?.map(item => `${item.timeFrom} - ${item.timeTo}`)

  const basicInformationInfo = [
    { label: t('label.gender'), value: capitalize(user?.gender) },
    { label: t('label.birth'), value: formatDate(user?.dateOfBirth) },
    {
      label: t('label.nationality'),
      value: user?.nationalities.map(item => item.label)
    },
    { label: t('label.first-language'), value: user?.firstLanguage?.label },
    { label: t('label.community'), value: user?.community?.label },
    {
      label: t('label.ahv-number'),
      value: user?.ahvNumber
    }
  ]

  const academicInfo = [
    {
      label: t('label.class-tutor'),
      value: (
        <>
          {user?.tutor?.classes.map(classItem => (
            <LinkText
              size="medium"
              key={classItem.id}
              to="/students-and-classes/classes/$classId/details"
              params={{
                classId: classItem.id
              }}
            >
              {classItem.name}
            </LinkText>
          ))}
        </>
      ),
      hidden: !user?.profiles.includes(USER_PROFILE.TUTOR)
    },
    {
      label: t('label.preferred-room'),
      value: user?.teacher?.preferredRooms?.map(room => room.name) || [],
      hidden:
        !user?.profiles.includes(USER_PROFILE.TUTOR) &&
        !user?.profiles.includes(USER_PROFILE.TEACHER)
    }
  ].filter(item => !item.hidden)

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: user?.phoneNumber },
    { label: t('label.email'), value: user?.email },
    { label: t('label.emergency-contact'), value: user?.emergencyPhoneNumber }
  ]

  const weekdays = getWeekDays()

  const scheduleInfo = [
    {
      label: weekdays[0],
      value: getScheduleText(user?.teacher?.customSchedule?.monday)
    },
    {
      label: weekdays[1],
      value: getScheduleText(user?.teacher?.customSchedule?.tuesday)
    },
    {
      label: weekdays[2],
      value: getScheduleText(user?.teacher?.customSchedule?.wednesday)
    },
    {
      label: weekdays[3],
      value: getScheduleText(user?.teacher?.customSchedule?.thurdsay)
    },
    {
      label: weekdays[4],
      value: getScheduleText(user?.teacher?.customSchedule?.friday)
    },
    {
      label: weekdays[5],
      value: getScheduleText(user?.teacher?.customSchedule?.saturday)
    },
    {
      label: weekdays[6],
      value: getScheduleText(user?.teacher?.customSchedule?.sunday)
    }
  ]

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.details}>{t('header.details')}</p>
        {userStore?.isSuperAdmin ? (
          <Button
            asLink
            to="/users/edit/$userId"
            params={{ userId }}
            variant="secondary"
            icon={<EditIcon />}
          >
            {t('button.edit')}
          </Button>
        ) : null}
      </div>
      <div className={styles.wrapper}>
        <DataBlock
          header={t('header.basic-information')}
          infoArray={basicInformationInfo}
        />

        <DataBlock header={t('header.academics')} infoArray={academicInfo} />

        <DataBlock
          header={t('header.contact-details')}
          infoArray={contactDetailsInfo}
        />

        <DataBlock header={t('header.schedule')} infoArray={scheduleInfo} />
      </div>
    </div>
  )
}

export default UserDetailsView
