import { useTranslation } from 'react-i18next'

import InfoCircle from '@/assets/icons/info-circle.svg?react'
import Button from '@/components/Button/Button'
import Modal from '@/components/Modal/Modal'
import Tag from '@/components/Tag/Tag'
import { USER_PROFILE } from '@/constants/user-profile'

import styles from './UserRoleGuide.module.scss'
import { getColorByProfile, getLabelByProfile } from '../utils/getProfileTag'

const UserRoleGuide = () => {
  const { t } = useTranslation('users')

  const userRoles = [
    {
      role: USER_PROFILE.SUPERUSER,
      description: t('help.admin')
    },
    {
      role: USER_PROFILE.TEACHER,
      description: t('help.teacher')
    },
    {
      role: USER_PROFILE.TUTOR,
      description: t('help.tutor')
    },
    {
      role: USER_PROFILE.SUPPORT_STAFF,
      description: t('help.user')
    }
  ]

  return (
    <Modal
      id="user-role-guide"
      title={t('header.user-role-guide')}
      size="md"
      trigger={
        <Button
          variant="secondary"
          size="small"
          icon={<InfoCircle />}
          className={styles.button}
        >
          {t('button.user-role-guide')}
        </Button>
      }
    >
      <div className={styles.wrapper}>
        {userRoles.map(role => (
          <div key={role.role} className={styles.role}>
            <Tag
              variant="with-background"
              label={getLabelByProfile(role.role)}
              color={getColorByProfile(role.role)}
            />
            <p className={styles.description}>{role.description}</p>
          </div>
        ))}
      </div>
    </Modal>
  )
}
export default UserRoleGuide
