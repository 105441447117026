import { type Filter } from '@/components/common/Filters/Filters'

export const checkIfFilterSelected = (
  filters: Filter[],
  requiredFilterIds?: string[]
) =>
  filters.some(filter => {
    if (requiredFilterIds?.includes(filter.filterProps.id)) return false
    if (filter.variant === 'date-range')
      return filter.filterProps.value?.to || filter.filterProps.value?.from
    return filter.filterProps.value
  })
