import LinkText from '@/components/LinkText/LinkText'
import { type Column } from '@/components/Table/Table'
import Tag from '@/components/Tag/Tag'
import i18n from '@/i18n'
import { type Comment } from '@/queries/useComments'
import type { CommentType } from '@/types/comment-type'
import { commentTypeTags } from '@/utils/comment-type-tags'
import { formatDate } from '@/utils/format-date'

export const commonCommentsColumn: Column<Comment>[] = [
  {
    key: 'added-on',
    dataIndex: 'addedOn',
    title: i18n.t('header.added-on', { ns: 'common' }),
    width: 200,
    render: (value: Date) => formatDate(value)
  },
  {
    key: 'added-by',
    title: i18n.t('header.added-by', { ns: 'common' }),
    width: 200,
    render: (value: Comment) => (
      <LinkText
        to="/users/$userId/details"
        params={{
          userId: value.addedBy.id
        }}
      >
        {value.addedBy.name}
      </LinkText>
    )
  },
  {
    key: 'type',
    title: i18n.t('header.type', { ns: 'common' }),
    dataIndex: 'commentType',
    width: 200,
    render: (value: CommentType) => <Tag {...commentTypeTags[value]} />
  }
]
