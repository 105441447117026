import { createFileRoute } from '@tanstack/react-router'

import { commentFilterSchema, CommentsView } from '@/modules/students'

export const Route = createFileRoute(
  '/_auth/students-and-classes/students/$studentId/comments'
)({
  validateSearch: commentFilterSchema,
  component: CommentsView
})
