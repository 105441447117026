import { type SelectOption } from '@/components/Select/Select'
import i18n from '@/i18n'

export type Gender = 'male' | 'female'

const genderOptions: SelectOption<Gender>[] = [
  { label: i18n.t('common:text.male'), value: 'male' },
  { label: i18n.t('common:text.female'), value: 'female' }
]

export default genderOptions
