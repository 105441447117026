import { getRouteApi } from '@tanstack/react-router'
import { type DefaultValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import { getFullName } from '@/utils/get-full-name'

import StudentForm from '../components/StudentForm'
import { type StudentForm as StudentFormType } from '../constants/student-payload'
import useAddStudent from '../mutations/useAddStudent'

const guardianDefaultValues = {
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  sendActivationLink: false
}

const defaultValues: DefaultValues<StudentFormType> = {
  email: '',
  firstName: '',
  lastName: '',
  ahvNumber: '',
  birthDate: undefined,
  firstLanguage: undefined,
  nationalities: [],
  gender: undefined,
  community: '',
  phone: '',
  sendActivationLink: false,
  year: undefined,
  class: undefined,
  guardians: [{ ...guardianDefaultValues }, { ...guardianDefaultValues }]
}

const routeApi = getRouteApi('/_auth/students-and-classes/students/add/')

const StudentAddView = () => {
  const { t } = useTranslation(['students', 'common'])

  const navigate = routeApi.useNavigate()

  const {
    mutate: addStudent,
    isPending,
    formErrors
  } = useAddStudent({
    onSuccess: ({ response, variables }) => {
      let activationLinksSentCount = variables.guardians.filter(
        ({ sendActivationLink }) => sendActivationLink
      ).length
      if (variables.sendActivationLink) activationLinksSentCount += 1
      if (activationLinksSentCount) {
        toast({
          variant: 'success',
          title: t('toast.sent-activation-links', {
            count: activationLinksSentCount
          })
        })
      }
      toast({
        variant: 'success',
        title: t('toast.student-was-added', {
          STUDENT_NAME: getFullName(variables)
        })
      })
      navigate({
        to: '/students-and-classes/students/$studentId',
        params: {
          studentId: response.id
        }
      })
    },
    onError: data => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-add-student', {
          STUDENT_NAME: getFullName(data)
        })
      })
    }
  })

  return (
    <BasicLayout
      header={t('header.add-new-student')}
      moduleName={t('common:header.academics')}
    >
      <StudentForm
        formErrors={formErrors}
        onSubmit={addStudent}
        defaultValues={defaultValues}
        loading={isPending}
      />
    </BasicLayout>
  )
}

export default StudentAddView
