import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import SendLinkIcon from '@/assets/icons/send-link.svg?react'
import UnlockIcon from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'
import DataBlock from '@/components/DataBlock/DataBlock'
import LinkText from '@/components/LinkText/LinkText'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout, {
  type HeaderAction
} from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './ParentDetailsView.module.scss'
import useActivateParent from '../mutations/useActivateParent'
import useBlockParent from '../mutations/useBlockParent'
import useSendActivationLinkParent from '../mutations/useSendActivationLinkParent'
import useParent, { type Student } from '../queries/useParent'
import {
  getParentStatusTootlipText,
  getStudentStatusTootlipText
} from '../utils/statuses'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/parents/$parentId/details'
)

const ParentDetailsView = () => {
  const { t } = useTranslation('students')
  const { parentId } = routeApi.useParams()

  const { data: parent, refetch: refetchParentDetails } = useParent(parentId)

  const { user } = useAuthStore()

  const [openBlockParentConfirmModal, setOpenBlockParentConfirmModal] =
    useState(false)
  const [openActivateParentConfirmModal, setOpenActivateParentConfirmModal] =
    useState(false)

  const { mutate: blockParent, isPending: isBlockParentLoading } =
    useBlockParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-blocked', { NAME: parent?.fullName })
        })
        setOpenBlockParentConfirmModal(false)
        refetchParentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-blocked-failed', {
            NAME: parent?.fullName
          })
        })
      }
    })

  const { mutate: activateParent, isPending: isActivateParentLoading } =
    useActivateParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-activate', {
            NAME: parent?.fullName
          })
        })
        setOpenActivateParentConfirmModal(false)
        refetchParentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-activate', {
            NAME: parent?.fullName
          })
        })
      }
    })

  const { mutate: sendActivationLinkParent } = useSendActivationLinkParent({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.successfully-send-activation-link')
      })
      refetchParentDetails()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-send-activation-link')
      })
    }
  })

  const actions: HeaderAction[] = user?.isSuperAdmin
    ? [
        {
          icon: <SendLinkIcon />,
          text:
            parent?.status === 'active'
              ? t('button.resend-activation-link')
              : t('button.send-activation-link'),
          onClick: () => sendActivationLinkParent({ id: parentId }),
          hidden: parent?.status === 'blocked'
        },
        {
          icon: <UnlockIcon />,
          onClick: () => {
            setOpenActivateParentConfirmModal(true)
          },
          loading: isActivateParentLoading,
          text: t('button.activate-parent'),
          hidden: parent?.status !== 'blocked'
        },
        {
          icon: <CancelIcon />,
          onClick: () => {
            setOpenBlockParentConfirmModal(true)
          },
          loading: isBlockParentLoading,
          text: t('button.block-parent'),
          variantAction: 'danger',
          hidden: parent?.status !== 'active'
        }
      ]
    : []

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: parent?.phoneNumber },
    { label: t('label.email'), value: parent?.email }
  ]

  return (
    <BasicLayout
      actions={actions}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{parent?.fullName}</h1>
          {parent?.status ? (
            <Tooltip
              trigger={
                <Tag
                  {...userStatusTags[parent.status]}
                  dataTestId="status-tag"
                  variant="on-blue"
                />
              }
              text={getParentStatusTootlipText(parent.status)}
            />
          ) : null}
        </div>
      }
      moduleName={t('header.academics', { ns: 'common' })}
    >
      <>
        <div className={styles.sectionWrapper}>
          <div className={styles.container}>
            <p className={styles.subheader}>{t('header.details')}</p>
            {user?.isSuperAdmin ? (
              <Button
                asLink
                to="/students-and-classes/students/parents/$parentId/edit"
                params={{ parentId }}
                variant="secondary"
                icon={<EditIcon />}
              >
                {t('button.edit')}
              </Button>
            ) : null}
          </div>
          <div className={styles.wrapper}>
            <DataBlock
              header={t('header.contact-details')}
              infoArray={contactDetailsInfo}
            />
          </div>
        </div>

        <div>
          <div className={styles.container}>
            <p className={styles.subheader}>{t('header.students')}</p>
          </div>
          <div className={styles.wrapper}>
            {parent?.students.map(student => (
              <Student key={student.id} student={student} />
            ))}
          </div>
        </div>

        <ConfirmModal
          id="block-parent-modal"
          header={t('header.block-parent')}
          subheader={t('help.want-to-block-parent', {
            PARENT: parent?.fullName
          })}
          confirmButton={
            <Button
              variant="danger"
              onClick={() => blockParent({ id: parentId })}
              loading={isBlockParentLoading}
            >
              {t('button.block')}
            </Button>
          }
          open={openBlockParentConfirmModal}
          onOpenChange={setOpenBlockParentConfirmModal}
        />
        <ConfirmModal
          id="activate-parent-modal"
          header={t('header.activate-parent')}
          subheader={t('help.want-to-activate-parent', {
            PARENT: parent?.fullName
          })}
          confirmButton={
            <Button
              onClick={() => activateParent({ id: parentId })}
              loading={isActivateParentLoading}
            >
              {t('button.activate')}
            </Button>
          }
          open={openActivateParentConfirmModal}
          onOpenChange={setOpenActivateParentConfirmModal}
        />
      </>
    </BasicLayout>
  )
}

type StudentProps = { student: Student }

const Student = (props: StudentProps) => {
  const { t } = useTranslation('students')

  const academicInfo = [
    {
      label: t('label.phone-number'),
      value: props.student.phoneNumber
    },
    {
      label: t('label.email'),
      value: props.student.email
    },

    {
      label: t('label.status'),
      value: (
        <Tooltip
          trigger={<Tag {...userStatusTags[props.student.status]} />}
          text={getStudentStatusTootlipText(props.student.status)}
        />
      )
    },
    {
      label: t('label.year'),
      value: props.student.year
    },
    {
      label: t('label.class'),
      value: props.student.studentClass ? (
        <LinkText
          size="medium"
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: props.student.studentClass.id
          }}
        >
          {props.student.studentClass.name}
        </LinkText>
      ) : null
    }
  ]

  return (
    <DataBlock
      header={
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: props.student.id
          }}
        >
          <span className={styles.studentName}>{props.student.fullName}</span>
        </LinkText>
      }
      infoArray={academicInfo}
    />
  )
}

export default ParentDetailsView
