import type {
  Control,
  FieldPath,
  FieldPathValue,
  FieldValues
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Checkbox from '@/components/Checkbox/Checkbox'
import FormField from '@/components/FormField'
import type { UserStatus } from '@/types/user-status'

type BooleanFieldNames<T extends FieldValues> = {
  [K in FieldPath<T>]: FieldPathValue<T, K> extends boolean ? K : never
}[FieldPath<T>]

type SendActivationLinkFormFieldProps<TData extends FieldValues> = {
  id: string
  name: BooleanFieldNames<TData>
  control: Control<TData>
  userStatus?: UserStatus
}

const SendActivationLinkFormField = <TData extends FieldValues>(
  props: SendActivationLinkFormFieldProps<TData>
) => {
  const { t } = useTranslation('common')
  return (
    <FormField
      control={props.control}
      id={props.id}
      required
      name={props.name}
      render={({ inputProps }) => (
        // @ts-expect-error name always corresponds to a boolean field, inputProps.value is always a boolean
        // types are checked correctly when using the component, but for some reason can't be correctly resolved here
        <Checkbox
          {...inputProps}
          disabled={props.userStatus === 'blocked'}
          label={
            props.userStatus === 'active'
              ? t('label.resend-activation-link')
              : t('label.send-activation-link')
          }
          tooltip={
            !props.userStatus || props.userStatus === 'inactive'
              ? t('help.activation-link-information')
              : undefined
          }
        />
      )}
    />
  )
}

export default SendActivationLinkFormField
